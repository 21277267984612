<template>
  <div class="main-container">
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.main-container {
  width: 100%;
  height: 100%;
}
</style>

<script>
import './assets/css/App.css'

export default {
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
  }
}
</script>
