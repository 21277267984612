<template>
  <div class="home">
    <div class="block-button-container">
      <div class="block-button-row">
        <div class="block-button" @click="goAdminPage">
          <img src="../assets/images/authorization.png" />
          管理界面
        </div>
        <div class="block-button" @click="goReservePage">
          <img src="../assets/images/verification.png" />
          立即预约
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      siteId: 2
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    goAdminPage () {
      this.$router.replace('/admin/Admin?site=' + this.siteId)
    },
    goReservePage () {
      this.$router.replace('/site?site=' + this.siteId)
    }
  },
  mounted () {
    document.title = '预约'
    const query = this.$route.query
    console.log('site id is:' + query.site)
    this.siteId = query.site ? query.site : 2
  }
}
</script>
<style scoped>
.block-button-container {
  margin-top: 10px;
}
.block-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.block-button {
  box-sizing: border-box;
  flex: 1;
  height: 140px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px #CDCDCD;
  font-size: 18px;
  font-weight: bold;
  color: #777777;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block-button:nth-child(2) {
  margin-left: 10px;
}
.block-button:active {
  background-color: #EFEFEF;
}
.block-button img {
  height: 80px;
  width: 80px;
  margin: 10px 0px;
}
</style>
