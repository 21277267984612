import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/sitelist',
    name: 'SiteList',
    meta: {
      title: '列表'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SiteList.vue')
  },
  {
    path: '/site',
    name: 'Site',
    meta: {
      title: '预约'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SiteDetail.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    meta: {
      title: '我的预约记录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Mine.vue')
  },
  {
    path: '/qr',
    name: 'Qr',
    meta: {
      title: '我的预约码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Qr.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '授权登录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    meta: {
      title: '设备扫码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/DeviceScan.vue')
  },
  {
    path: '/admin/Admin',
    name: 'AdminHome',
    meta: {
      title: '管理员'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Admin.vue')
  },
  {
    path: '/admin/apply',
    name: 'AdminApply',
    meta: {
      title: '申请成为管理员'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Apply.vue')
  },
  {
    path: '/admin/reserveList',
    name: 'AdminReserveList',
    meta: {
      title: '预约记录管理'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminReserveList.vue')
  },
  {
    path: '/admin/reserveDetail',
    name: 'AdminReserveDetail',
    meta: {
      title: '预约详情'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ReserveDetail.vue')
  },
  {
    path: '/admin/reserveVerify',
    name: 'ReserveVerify',
    meta: {
      title: '预约核验'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ReserveVerify.vue')
  },
  {
    path: '/admin/siteManagerAuth',
    name: 'AdminSiteManagerAuth',
    meta: {
      title: '管理员授权'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/SiteManagerAuth.vue')
  },
  {
    path: '/addDevice',
    name: 'AddDevice',
    meta: {
      title: '添加设备'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/DeviceAddEdit.vue')
  },
  {
    path: '/club/invite',
    name: 'ClubInvitee',
    meta: {
      title: '中国留学生论坛社交晚宴派对'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/club/invite.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
